/******************************************************************************\
 * File: collections.js
 *
 * Author: Gigster
 *
 * Description: Checkbox functionality
 *
 * Notes:
 \******************************************************************************/

export const collections = [
    {
        tag: 'letsride',
        collectionDisplay: "LET'S RIDE",
        tags: ['day-rides', 'overnight-rides', 'short-rides'],
        key: 'tag',
        lat: 37.9609055,
        lng: -98.599585,
        zoom: 5,
        radius: 1800,
        title: "LET'S RIDE",
        type: 'rides'
    },
    {
        collection: 'RIDE 365',
        collectionDisplay: 'RIDE 365',
        tag: 'hog-10for20',
        key: 'list',
        lat: 37.9609055,
        lng: -98.599585,
        zoom: 5,
        radius: 4000,
        logo: '/img/image-hog-logo.svg',
        title: '10 RIDES FOR ’20',
        type: 'rides',
        description:
            '10 Rides for ‘20 is an annual H.O.G. member challenge featuring 10 great destinations throughout the country, along with a recommended scenic route for each.'
    },
    {
        collection: 'RIDE 365',
        collectionDisplay: 'RIDE 365',
        tag: 'hog-10for21',
        key: 'list',
        lat: 37.9609055,
        lng: -98.599585,
        zoom: 5,
        radius: 4000,
        logo: '/img/image-hog-logo.svg',
        title: '10 for 21',
        type: 'rides',
        description:
            '10 Rides for ‘21 is an annual H.O.G. member challenge featuring 10 great destinations throughout the country, along with a recommended scenic route for each.'
    },
    {
        collection: 'RIDE 365',
        collectionDisplay: 'RIDE 365',
        tag: 'hog-15for25',
        key: 'list',
        catalogHrn: 'hrn:here:data::org829441984:hdrecommendedrides-v1',
        layerId: 'recomended_routes',
        lat: 37.9609055,
        lng: -98.599585,
        zoom: 5,
        radius: 4000,
        logo: '/img/image-hog-logo.svg',
        title: '15 for 25',
        type: 'rides',
        active: true,
        description:
            '15 Rides for ‘25 is an annual H.O.G. member challenge featuring 15 great destinations throughout the country, along with a recommended scenic route for each.'
    },
    {
        collection: 'RIDE 365',
        collectionDisplay: 'RIDE 365',
        tag: 'hog-50rides1nation',
        key: 'list',
        lat: 37.9609055,
        lng: -98.599585,
        zoom: 5,
        radius: 4000,
        logo: '/img/image-hog-logo.svg',
        title: '50 RIDES, ONE NATION',
        type: 'rides',
        active: true,
        description:
            '50 Rides, One Nation is a H.O.G. member challenge that presents 50 “rides of a lifetime,” with no time limit to complete them. With one ride in each state, you’ll explore some of the country’s most iconic places, with thrilling riding and great scenery along the way.'
    }
];
