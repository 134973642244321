/******************************************************************************\
 * File: index.jsx
 *
 * Author: Gigster
 *
 * Description: This is the entry point for the web app
 *
 * Notes:
 \******************************************************************************/

//Why did you render library
//import './wdyr';
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux';
import CustomRouter from './components/CustomRouter';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import '@/index.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import '@/helpers/polyfill';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import App from '@/components/App';
import BasicAuth from '@/components/BasicAuth';
import { printEnv } from '@/shared/debug';
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import store from '@/store';
//------------------------------------------------------------------------------
// History ---------------------------------------------------------------------
import history from '@/helpers/history';
import '@/helpers/logging';
import HistoryListener from '@/components/helpers/HistoryListener';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';

// Renderer --------------------------------------------------------------------
const container = document.getElementById('app');
const root = createRoot(container);
const queryClient = new QueryClient();

const render = (Component) =>
    root.render(
        <BasicAuth required={process.env.USE_AUTH}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store} context={ReactReduxContext}>
                    <CustomRouter history={history}>
                        <HistoryListener>
                            <Component history={history} />
                        </HistoryListener>
                    </CustomRouter>
                </Provider>
            </QueryClientProvider>
        </BasicAuth>
    );
render(App);

//------------------------------------------------------------------------------
if (
    process.env.NODE_ENV === 'development' ||
    process.env.ENVIRONMENT === 'dev'
) {
    window.__defineGetter__('store', () => store);
    window.__defineGetter__('state', () => store.getState());
}

printEnv();

datadogRum.init({
    applicationId: process.env.DD_APPLICATION_ID,
    clientToken: process.env.DD_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.DD_SITE,
    service: process.env.DD_SERVICE,
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input'
});