/******************************************************************************\
 * File: Map.jsx
 *
 * Author: Gigster
 *
 * Description: Abstracted Map component
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { lazy, Suspense, useEffect, useState } from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import ErrorBoundary from '@/components/common/ErrorBoundary';
import Loading from '@/components/common/loading/Loading';
import { getPlatformInstance } from '@/helpers/here';

const HereMap = lazy(() => import('@/components/common/map/here/Map'));
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Map = ({ ...props }) => {
    const [hereInstance, setHereInstance] = useState(null);

    useEffect(() => {
        const load = async () => {
            const instance = await getPlatformInstance();
            setHereInstance(instance);
        };
        load();
    }, []);

    return (
        <ErrorBoundary>
            {hereInstance ? (
                <Suspense fallback={<Loading loading />}>
                    <HereMap here={hereInstance} {...props} />
                </Suspense>
            ) : (
                <Loading loading />
            )}
        </ErrorBoundary>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Map;
