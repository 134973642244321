/******************************************************************************\
 * File: routing.js
 *
 * Author: Gigster
 *
 * Description: Node.JS code shared between the frontend and backend.
 *
 * Notes:
 \******************************************************************************/

exports.Routes = {
    // Home
    HOME: '/',

    // Profile
    PROFILE: '/profile',
    SAVED: '/profile/saved',

    // Map
    MAP: '/map',
    MAP_SECTION: '/map/:section',
    MAP_RIDES: '/map/rides',
    MAP_RIDE: '/map/rides/:id',
    MAP_COLLECTIONS: '/map/collections',
    MAP_DEALERS: '/map/dealers',
    MAP_DEALER: '/map/dealers/:id',
    MAP_EVENTS: '/map/events',
    MAP_EVENT: '/map/events/:id',
    SHARED_EVENT: '/share/events/:id',

    // Ride Create
    RIDE_CREATE: '/map/create',
    RIDE_EDIT: '/map/rides/:id/edit',

    // Ride Preview
    RIDE_CREATE_PREVIEW: '/map/create/preview',
    RIDE_PREVIEW: '/map/rides/:id/preview',

    // Challenges
    CHALLENGES: '/challenges',
    CHALLENGE: '/challenges/:id',
    SHARED_CHALLENGE: '/share/challenges/:id',

    COLLECTIONS: '/collections',
    COLLECTION: '/collections/:name',

    BADGES: '/share/badges',
    SHARED_RIDE: '/share/rides/:id',

    SHOW: '/show/:id',

    // health check 
    HEALTH_CHECK: '/health'
};

// wild redirects end in *, they're wild.
exports.Redirects = {
    '/map/ride*': '/map/rides',
    '/map/dealer*': '/map/dealers',
    '/map/event*': '/map/events',
    '/map/rides/create/preview': '/map/create/preview',
    '/saved': '/profile/saved',
    '/create': '/map/create'
};
