import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LOCATION_CHANGE } from '@/helpers/constants';
import store from '@/store';
import { datadogRum } from '@datadog/browser-rum';

/* This component will allow us to capture every location change and dispatch specific actions based on that */
const HistoryListener = ({
    children
    //someAction,
}) => {
    const location = useLocation();
    // locations listener
    useEffect(() => {
        //do stuff on location change
        store.dispatch({ type: LOCATION_CHANGE, payload: { location } });
    }, [location]);

    useEffect(() => {
        datadogRum.startView({ name: location.pathname });
    }, [location.pathname]);

    return children;
};

// this can be used to dispatch specific actions based on location
const mapDispatchToProps = (dispatch) => ({
    //someAction: () => dispatch(someAction()),
});

export default connect(null, mapDispatchToProps)(HistoryListener);
